<template>
  <com-mon>
    <div>
        <div class="thetopbox" >
          <div>
            <span>1.配置菜单和点击对应菜单回复的内容，客户进入会话，自动发送该消息，引导客户自助查询问题或资料。</span>
            <el-popover placement="bottom" trigger="hover">
              <img :src="imgurl+'/image/menumessageimg.png'" style="width:280px" alt="">
              <el-button slot="reference" type="text">查看示例</el-button>
            </el-popover>
          </div>
          <div>
            <span>2.菜单消息添加成功后，可进入客服帐号管理页面，针对不同的客服帐号设置需要回复的菜单消息。</span>
            <el-button type="text" @click="jump(1)">前往配置</el-button>
          </div>
        </div>
        <el-card style="margin-top:10px">
          <buttonPermissions :datas = "'menumessagepush'" style="margin-right:10px">
            <el-button type="primary" @click="edit({Id:0})">添加菜单消息</el-button>
          </buttonPermissions>
          <el-table :data="tablelist" v-loading="tableloading" style="margin-top:10px">
            <el-table-column prop="Name" label="名称"></el-table-column>
            <el-table-column prop="" label="引导语">
              <template slot-scope="scope">
                <div class = "fonthidden">
                  {{scope.row.GuideContent}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="AddTime" label="创建时间"></el-table-column>
            <el-table-column prop="" label="操作" width="200">
              <template slot-scope="scope">
                <buttonPermissions :datas="'menumessageedit'" style="margin-right:10px">
                  <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                </buttonPermissions>
                <buttonPermissions :datas="'menumessageedel'" style="margin-right:10px">
                  <el-button type="text" style="color:#f56c6c" @click="del(scope.row)">删除</el-button>
                </buttonPermissions>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top:10px;text-align:right" v-if="total">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </el-card>
    </div>
  </com-mon>
</template>

<script>
import config from "@/config"
import Commom from "@/views/customerServe/component/Common.vue"//引入组件
import{
  qyWeixinKfMenuMessagelist,
  qyWeixinKfMenuMessagedelete
}from "@/api/sv3.0.0"
import buttonPermissions from '@/components/buttonPermissions';
export default {
  components:{
    buttonPermissions,
     "com-mon":Commom
  },
  data () {
    return {
      imgurl:config.IMG_BASE,
      tablelist:[{Id:1}],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:null,
    }
  },
  created () {
    this.gettablelist()
  },
  methods: {
    edit(e){
      this.$router.push({
        path:'/customerServe/WxCustomerServe/editmenumessage',
        query:{
          Id:e.Id
        }
      })
    },
    del(e){
      this.$confirm('删除后，不可恢复，且客户进入会话时，将不再发送该菜单消息，是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.todel(e.Id)
      }).catch(() => {});
    },
    async todel(id){
      try{
        this.tableloading = true
        let data = {
          Id:await id
        }
        let res = await qyWeixinKfMenuMessagedelete(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        let res = await qyWeixinKfMenuMessagelist(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    jump(e){
      if(e==1){
        this.$router.push({
          path:'/customerServe/WxCustomerServe/serviceaccount'
        })
      }
    },
  }
}
</script>

<style lang = "less" scoped>
  .thetopbox{
    background: white;
    font-size: 14px;
    color: #606266;
    padding: 10px 20px;
    line-height: 1.5;
  }
  .fonthidden{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
</style>